.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.youtube-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #ff0000;
  z-index: 9999;
  animation: youtube-loading 10s ease-in-out infinite;
  transform-origin: 0 50%;
}

@keyframes youtube-loading {
  0% {
    transform: scaleX(0);
  }
  10% {
    transform: scaleX(0.5);
  }
  25% {
    transform: scaleX(0.75);
  }
  50% {
    transform: scaleX(0.9);
  }
  100% {
    transform: scaleX(1);
  }
}
